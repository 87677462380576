<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="13"
    viewBox="0 0 15 13"
  >
    <path
      class="a"
      d="M15.844,7.648,9.016,2.181a.826.826,0,0,0-1.032,0L1.156,7.648a.413.413,0,0,0,.258.736h1.3V15H6.847V11.706a1.652,1.652,0,1,1,3.3,0V15h4.132V8.384h1.3A.413.413,0,0,0,15.844,7.648Z"
      transform="translate(-1 -2)"
    />
  </svg>
</template>

<script>
export default {
  name: "Home",
};
</script>

<style lang="scss" scoped>
.a {
  fill: $ocean;
}
</style>